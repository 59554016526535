<template>
    <div style="width: 100%; text-align: center">
        <h1>404 - Not Found</h1>
        <p>
            Whoopsie! The page you are looking for does not exist.
        </p>
    </div>
</template>

<script>
    export default {
        setup() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>

</style>